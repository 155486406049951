<script lang="ts" setup>
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  selectedEntity: {
    type: String,
    required: false,
    default: '',
  },
  newVersionAvailable: {
    type: Boolean,
    required: true,
    default: false,
  },
})
const emit = defineEmits(['close', 'load_latest_version'])
function closeModal() {
  emit('close')
}
function loadLatestVersion() {
  emit('load_latest_version')
  closeModal()
}

const state = reactive({
  time: 60,
})

function timeRemaining() {
  const interval = setInterval(() => {
    state.time -= 1
    if (state.time === 0) {
      clearInterval(interval)
      loadLatestVersion()
    }
  }, 1000)
}

watch(
  () => props.newVersionAvailable,
  newVal => {
    if (newVal) {
      timeRemaining()
    }
  },
)
</script>

<template>
  <OModal :open="props.open" @close="closeModal">
    <template #content>
      <div class="flex flex-col gap-4 text-primary">
        <div class="flex flex-col gap-1">
          <h2 class="text-xl font-semibold">
            {{ $t('global.new_version') }}
          </h2>
        </div>

        <div class="grid grid-cols-1 items-center gap-2">
          <p>{{ $t('global.click_button_reload', { seconds: state.time }) }}</p>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end items-center w-full gap-4">
        <button class="btn-secondary w-full" @click="closeModal">
          {{ $t('global.hide') }}
        </button>
        <button class="btn-primary w-full" @click="loadLatestVersion">
          <CIcon name="reset" class="mr-1" />
          {{ $t('global.reload') }}
        </button>
      </div>
    </template>
  </OModal>
</template>
