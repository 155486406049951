import type { Tool } from '@mistralai/mistralai/models/components/tool'
import { findBom, findBomTool } from './findBom'
import { getBomContent, getBomContentTool } from './getBomContent'
import { selectBoms, selectBomsTool } from './selectBoms'

export const tools: Tool[] = [findBomTool, getBomContentTool, selectBomsTool]

export const toolFunctions = {
  findBom,
  getBomContent,
  selectBoms,
}
