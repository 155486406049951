<script setup lang="ts">
import { ref } from 'vue'
import { useApiRequest } from '~/composables/useApiRequest'
import { useKeyupHandler } from '~/composables/useKeyupHandler'
import { ETLTypes, ETLTypesConfig } from '~/config/etl'
import { AI } from '~/controllers/ai'
import { BomImportData } from '~/controllers/ai/clients/anthropic/tools/createBomCSV'
import { apiStore } from '~/stores/api'
import { unwrapApiErrors } from '~/types/api'
import { ToastList } from '~/types/toast'
import { stripCommas } from '~/utils/string'

defineProps<{ open: boolean }>()

const emit = defineEmits<{ (e: 'close'): void }>()

const userPrompt = ref('')
const assistantResponse = ref('')
const state = ref('')
const file = ref<File | null>(null)
const signed_url = ref('')

const errorMessage = ref('')

const { t } = useI18n()
const toasts = inject<ToastList>('toast')

async function generateBom() {
  if (!userPrompt.value.trim()) return

  try {
    state.value = t('global.generating_bom')
    const stream = AI.createBomCSV({ userPrompt: userPrompt.value })
    for await (const chunk of stream) {
      switch (chunk.type) {
        case 'text':
          assistantResponse.value += chunk.content
          break
        case 'json':
          createBomFromJson(chunk.parsed as BomImportData)
          break
        case 'error':
          errorMessage.value = t('global.generation_failed') + chunk.content
          state.value = ''
          break
      }
    }
  } catch (error) {
    errorMessage.value = t('global.generation_failed') + unwrapApiErrors(error)
    state.value = ''
  }
}

function createBomFromJson(json: BomImportData | string) {
  state.value = t('global.creating_import_file')

  if (typeof json === 'string') {
    errorMessage.value = t('global.generation_failed')
    state.value = ''
    return
  }

  const csv = buildCSV(json.nodes)

  uploadCSV(csv)
}

const uploadRequest = useApiRequest(async api => {
  if (!file.value) return
  const response = await api.uploadFile(file.value)
  signed_url.value = response.data.url
})

async function uploadCSV(csv: string) {
  const api = apiStore().getApiClient
  state.value = t('global.uploading_import_file')

  // Add BOM marker for Excel compatibility
  const BOM = '\uFEFF'
  const csvContent = BOM + csv

  // Create blob with explicit UTF-8 encoding
  const blob = new Blob([csvContent], {
    type: 'text/csv;charset=utf-8',
  })

  // Create file with more detailed mime type
  file.value = new File([blob], 'bom.csv', {
    type: 'text/csv;charset=utf-8',
    lastModified: Date.now(),
  })

  const result = await uploadRequest.runRequest()

  if (!result) {
    return
  }

  if (signed_url.value) {
    try {
      await api.importETL({
        type: ETLTypesConfig[ETLTypes.GENERIC_BOM].payload_type,
        url: signed_url.value,
      })
      emit('close')
      toasts?.success(t('global.success'), t('entities.import_upload_success'))
    } catch (error) {
      toasts?.error(t('global.error'), t('global.file_upload_failed') + unwrapApiErrors(error))
    } finally {
      userPrompt.value = ''
      state.value = ''
    }
  } else {
    toasts?.error(t('global.error'), t('global.file_upload_failed'))
    state.value = ''
  }
}

function buildCSV(nodes: BomImportData['nodes']) {
  const data = [['level', 'reference', 'name', 'type', 'description', 'uom', 'quantity'].join(',')]

  nodes.forEach((node, index) => {
    data.push(
      [
        node.level,
        node.reference,
        stripCommas(node.name),
        index === 0 ? '' : node.type,
        stripCommas(node.description),
        node.uom,
        node.quantity,
      ].join(','),
    )
  })

  return data.join('\n')
}

useKeyupHandler((e: KeyboardEvent) => {
  if (e.ctrlKey || e.metaKey || e.altKey) {
    generateBom()
  }
}, 'Enter')
</script>

<template>
  <OModal :open="open" @close="emit('close')">
    <template #content>
      <div class="flex flex-col gap-4">
        <div class="flex items-center justify-between w-full gap-2">
          <div class="text-xl font-semibold">{{ t('global.generate_bom') }}</div>
          <button class="text-gray-500" @click="emit('close')">
            <CIcon name="close" class="w-6 h-6" />
          </button>
        </div>
        <FormKit
          v-model="userPrompt"
          type="textarea"
          :label="t('global.prompt')"
          class="w-full h-32 p-2 border rounded-md"
          :placeholder="t('global.create_bom_prompt')"
        ></FormKit>

        <div v-if="assistantResponse" class="text-gray-500 rounded border-gray-50 p-2">
          <CText size="s" color="gray" type="secondary">{{ assistantResponse }}</CText>
        </div>

        <div v-if="state" class="flex items-center gap-2 bg-gray-50 rounded border-gray-300 p-2">
          <Loader size="0.2" />
          <CText size="s" color="gray" type="primary">{{ state }}</CText>
        </div>

        <div v-if="errorMessage" class="flex items-center gap-2 text-red-500 rounded bg-red-50 border-red-50 p-2">
          <CIcon name="warning-alt" class="w-4 h-4" />
          <CText size="s" color="red" type="secondary">{{ errorMessage }}</CText>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex items-end justify-end gap-2 w-full">
        <button class="btn-secondary" @click="emit('close')">{{ $t('global.cancel') }}</button>
        <button class="btn-primary" :disabled="!!state || !userPrompt" @click="generateBom">
          {{ $t('global.generate') }}
        </button>
      </div>
    </template>
  </OModal>
</template>
