import OpenAI from 'openai'
import { z } from 'zod'
import { BaseAIClient } from '../../baseClient'
import { AIClient, AICompletionOptions, AICompletionResponse, AIModel, AIStreamChunk } from '../../interface'
import { toolFunctions, tools } from './tools'

const SHOULD_YIELD_TOOLS = ['selectBoms', 'createBomCSV']

function isErrorChunk(
  chunk: OpenAI.ChatCompletionChunk | { type: 'error'; content: string },
): chunk is { type: 'error'; content: string } {
  return 'type' in chunk && chunk.type === 'error'
}

export class OpenAIClient implements AIClient {
  private client: BaseAIClient<
    OpenAI.ChatCompletionCreateParams,
    OpenAI.ChatCompletionChunk | { type: 'error'; content: string },
    OpenAI.ChatCompletion
  >
  readonly provider = 'openai'
  readonly models: AIModel[] = [
    {
      id: 'gpt-4-turbo-preview',
      name: 'GPT-4 Turbo',
      contextWindow: 128000,
      supportsJson: true,
    },
    {
      id: 'gpt-4',
      name: 'GPT-4',
      contextWindow: 8192,
      supportsJson: true,
    },
    {
      id: 'gpt-3.5-turbo',
      name: 'GPT-3.5 Turbo',
      contextWindow: 16385,
      supportsJson: true,
    },
  ]

  constructor() {
    this.client = new BaseAIClient<
      OpenAI.ChatCompletionCreateParams,
      OpenAI.ChatCompletionChunk | { type: 'error'; content: string },
      OpenAI.ChatCompletion
    >({
      url: '/api/openai/chat',
    })
  }

  getAvailableModels(): AIModel[] {
    return this.models
  }

  async getCompletion(options: AICompletionOptions): Promise<AICompletionResponse> {
    const response = await this.client.getCompletion({
      model: options.model,
      messages: [
        ...(options.systemPrompt ? [{ role: 'system' as const, content: options.systemPrompt }] : []),
        { role: 'user', content: options.userPrompt },
      ],
      temperature: options.temperature ?? 0.7,
      max_tokens: options.maxTokens,
      response_format: options.schema ? { type: 'json_object' } : undefined,
    })

    const content = options.schema
      ? JSON.parse(response.choices[0].message.content || '{}')
      : response.choices[0].message.content

    return {
      content,
      model: response.model,
      provider: this.provider,
      usage: {
        promptTokens: response.usage?.prompt_tokens || 0,
        completionTokens: response.usage?.completion_tokens || 0,
        totalTokens: response.usage?.total_tokens || 0,
      },
    }
  }

  async *getStream(
    options: AICompletionOptions & { messages?: OpenAI.ChatCompletionMessageParam[] },
  ): AsyncGenerator<AIStreamChunk> {
    const messages = options.messages ?? [
      ...(options.systemPrompt ? [{ role: 'system' as const, content: options.systemPrompt }] : []),
      { role: 'user', content: options.userPrompt },
    ]

    const completion = await this.client.messages({
      model: options.model,
      messages,
      temperature: options.temperature ?? 0.7,
      max_tokens: options.maxTokens,
      // When using structured output, OpenAI doesn't return any explanation, so we're using tool calls instead
      // response_format: options.schema ? zodResponseFormat(options.schema, 'boms-list') : undefined,
      stream: true,
      tools,
      tool_choice: 'required',
      parallel_tool_calls: false,
    })

    let currentText = ''
    let currentToolCall: { index: number; name: string; id: string; arguments: string; shouldYield: boolean } | null =
      null

    for await (const chunk of completion) {
      if (isErrorChunk(chunk)) {
        yield chunk
        return
      }

      const delta = chunk.choices?.[0]?.delta

      if (!delta) continue

      // Handle tool calls
      if (delta.tool_calls?.[0]) {
        const toolCall = delta.tool_calls[0]

        // Initialize or update tool call
        if (!currentToolCall) {
          currentToolCall = {
            index: toolCall.index,
            name: toolCall.function?.name || '',
            id: toolCall?.id || '',
            arguments: toolCall.function?.arguments || '',
            shouldYield: SHOULD_YIELD_TOOLS.includes(toolCall.function?.name || ''),
          }
        } else {
          currentToolCall.arguments += toolCall.function?.arguments || ''
        }
      }

      // If this is the last chunk for this tool call
      if (currentToolCall && chunk.choices[0].finish_reason === 'tool_calls') {
        // Execute the tool
        const toolResult = await this.executeToolCall(
          currentToolCall.name,
          JSON.parse(currentToolCall.arguments ?? '{}'),
        )

        const newMessages = []

        if (currentToolCall.shouldYield) {
          yield {
            type: 'json',
            content: toolResult,
            parsed: JSON.parse(toolResult),
          }
        } else {
          // Start a new completion with the tool result
          newMessages.push(
            ...messages,
            {
              role: 'assistant' as const,
              content: '',
              tool_calls: [
                {
                  id: currentToolCall.id,
                  type: 'function' as const,
                  function: { name: currentToolCall.name, arguments: currentToolCall.arguments },
                },
              ],
            },
            {
              role: 'user' as const,
              content: JSON.stringify(toolResult),
              tool_call_id: currentToolCall.id,
            },
          )
        }

        if (newMessages.length) {
          // Get response after tool call
          const toolResponse = this.getStream({
            ...options,
            messages: newMessages,
          })

          // Stream all chunks from the tool response
          yield* toolResponse
          return
        }
      }

      // Handle regular text content
      if (delta.content) {
        currentText += delta.content
        yield {
          type: 'text',
          content: delta.content,
        }
      }

      // Handle end of stream
      if (chunk.choices[0].finish_reason === 'stop') {
        // If we have a schema, try to parse the accumulated text as JSON
        if (currentToolCall && options.schema && currentText) {
          try {
            const jsonContent = JSON.parse(currentText)
            const parsed = options.schema.parse(jsonContent)

            yield {
              type: 'json',
              content: currentText,
              parsed,
              done: true,
            }
          } catch (error) {
            yield {
              type: 'error',
              content: error instanceof Error ? error.message : 'An unexpected error occurred',
              done: true,
            }
          } finally {
            currentText = ''
            currentToolCall = null
          }
        } else {
          yield {
            type: 'text',
            content: '',
            done: true,
          }
        }
      }
    }
  }

  private getSchemaDescription(schema: z.ZodType, depth: number): string {
    const indent = '  '.repeat(depth)

    if (schema instanceof z.ZodObject) {
      const shape = schema._def.shape()
      const properties = Object.entries(shape).map(([key, value]) => {
        const type = this.getZodTypeName(value as z.ZodType, depth + 1)
        const required = !(value instanceof z.ZodOptional)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const description = (value as any)._def.description
        return `${indent}"${key}": ${type}${required ? ' (required)' : ' (optional)'}${description ? ` - ${description}` : ''}`
      })
      return `{\n${properties.join(',\n')}\n${indent}}`
    }

    return this.getZodTypeName(schema, depth)
  }

  private getZodTypeName(schema: z.ZodType, depth: number): string {
    if (schema instanceof z.ZodString) return 'string'
    if (schema instanceof z.ZodNumber) return 'number'
    if (schema instanceof z.ZodBoolean) return 'boolean'
    if (schema instanceof z.ZodArray) {
      const elementType = this.getSchemaDescription(schema._def.type as z.ZodType, depth)
      return `array of ${elementType}`
    }
    if (schema instanceof z.ZodObject) {
      return this.getSchemaDescription(schema, depth)
    }
    if (schema instanceof z.ZodOptional) {
      return this.getZodTypeName(schema._def.innerType, depth)
    }
    if (schema instanceof z.ZodEnum) {
      // @ts-expect-error - the depths of zod are unfathomable
      return `enum (one of: ${schema._def.values.map(v => `"${v}"`).join(', ')})`
    }
    if (schema instanceof z.ZodUnion) {
      // @ts-expect-error - the depths of zod are unfathomable
      return `one of: ${schema._def.options.map(opt => this.getZodTypeName(opt, depth)).join(' | ')}`
    }
    return 'unknown'
  }

  private async executeToolCall(name: string, args: unknown): Promise<string> {
    const func = toolFunctions[name as keyof typeof toolFunctions]
    if (!func) {
      throw new Error(`Unknown tool: ${name}`)
    }
    // @ts-expect-error - just invokes the right tool
    return func(args)
  }
}
