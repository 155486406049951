import OpenAI from 'openai'
import {
  selectBomsDescription,
  SelectBomsInput,
  selectBomsName,
  selectBomsSchema,
  selectBomsShared,
} from '../../sharedTools/selectBoms'

export const selectBomsTool: OpenAI.ChatCompletionTool = {
  type: 'function',
  function: {
    name: selectBomsName,
    description: selectBomsDescription,
    parameters: selectBomsSchema,
  },
}

export async function selectBoms(args: SelectBomsInput) {
  return selectBomsShared(args)
}
