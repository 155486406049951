import { debounce } from 'lodash'
import { defineStore } from 'pinia'
import { logout } from '~/controllers/authentication'

const LOGOUT_TIMEOUT = 1000 * 60 * (Number(import.meta.env.VITE_LOGOUT_TIMEOUT_MINUTES) || 20)
const TIMER_INTERVAL = Number(import.meta.env.VITE_TIMER_INTERVAL) || 5000

interface State {
  lastActive: Date
  timer: ReturnType<typeof setTimeout> | null
}

export const useUserActivity = defineStore({
  id: 'user-activity',
  state: (): State => ({
    lastActive: new Date(),
    timer: null,
  }),
  actions: {
    setUpListeners() {
      window.addEventListener('mousemove', debounce(this.updateLastActive, 1000))
      window.addEventListener('keydown', this.updateLastActive)
      window.addEventListener('click', this.updateLastActive)
      window.addEventListener('touchmove', debounce(this.updateLastActive, 1000))
      this.checkLastActive()
    },
    tearDownListeners() {
      window.removeEventListener('mousemove', this.updateLastActive)
      window.removeEventListener('keydown', this.updateLastActive)
      window.removeEventListener('click', this.updateLastActive)
      window.removeEventListener('touchmove', this.updateLastActive)
      this.resetTimer()
    },
    updateLastActive() {
      this.lastActive = new Date()
    },
    checkLastActive() {
      const now = new Date()
      const diff = now.getTime() - this.lastActive.getTime()

      if (diff > LOGOUT_TIMEOUT) {
        logout()
        window.location.href = '/auth/login'
      }

      this.timer = setTimeout(() => {
        this.checkLastActive()
      }, TIMER_INTERVAL)
    },
    resetTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
  },
})
