import Anthropic from '@anthropic-ai/sdk'
import {
  selectBomsDescription,
  SelectBomsInput,
  selectBomsName,
  selectBomsSchema,
  selectBomsShared,
} from '../../sharedTools/selectBoms'
import { AnthropicTool } from '../types'

const inputSchema: Anthropic.Tool['input_schema'] = selectBomsSchema

export const selectBomsTool: AnthropicTool<SelectBomsInput> = {
  name: selectBomsName,
  description: selectBomsDescription,
  inputSchema,
  func: async (input: SelectBomsInput) => {
    return selectBomsShared(input)
  },
}
