<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'
import CIcon from '~/components/UI/DesignSystem/CIcon.vue'
import { useTooltip } from '~/composables/useTooltip'
import { entityTypesStore } from '~/stores/entity_types'
import { Entity, EntityWithOptionalType, TreeViewInstance } from '~/types/view-elements'
import { getBackgroundColorForEntityTypeColor } from '~/utils/entityTypeColors'

/**
 * @entity : Entity
 * (The entity to display)
 *
 * @size : Number | String
 * (The size * 2 of the icon // size * 4 of the container in px)
 *
 * @class : String
 * (The class to add to the icon container)
 *
 * @tooltip : Boolean
 * (Whether to display a tooltip on hover)
 */
const props = defineProps({
  entity: {
    type: Object as PropType<Entity | TreeViewInstance>,
    required: true,
  },
  size: {
    types: [Number, String],
    required: false,
    default: 10,
  },
  class: {
    type: String,
    required: false,
    default: '',
  },
  tooltip: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const state = reactive({
  randUuid: uuidv4(),
})

const icon = computed(() => {
  return (entity: Pick<Entity, 'system_attributes'>) => {
    return entity?.system_attributes?.styling?.icon && entity?.system_attributes?.styling?.icon.startsWith('carbon-')
      ? entity?.system_attributes?.styling?.icon
      : (entity?.icon ?? 'carbon-cube')
  }
})

const iconColor = computed(() => {
  return (entity: Pick<Entity, 'system_attributes'>) => {
    return entity?.system_attributes?.styling?.color ?? entity?.color ?? '#181A4E'
  }
})

// Computed property to get display_name or fallback to name
const displayName = computed(() => {
  return (entity: EntityWithOptionalType) => {
    const entityTypes = entityTypesStore().getEntityTypesAsOptionsWithNameAsValue

    const getMatchedEntityDisplayName = (entityKey: string | undefined) => {
      if (!entityKey) return null
      const matchedEntity = entityTypes.find(e => e.name === entityKey)
      return matchedEntity?.display_name || entityKey
    }

    if ('entity_type' in entity) {
      return (
        getMatchedEntityDisplayName(entity.entity_type.display_name) || entity.name || entity.description || 'Unknown'
      )
    }

    // Check for 'group', 'type', or 'schema_type' in order of priority
    if ('group' in entity) {
      return getMatchedEntityDisplayName(entity.group) || entity.description || 'Unknown'
    }

    if ('type' in entity) {
      return getMatchedEntityDisplayName(entity.type) || entity.name || entity.description || 'Unknown'
    }

    if ('schema_type' in entity) {
      return getMatchedEntityDisplayName(entity.schema_type) || entity.name || entity.description || 'Unknown'
    }

    if ('entity_type_id' in entity) {
      const entityType = entityTypesStore().entity_types.list.find(item => item.id === entity.entity_type_id)
      return getMatchedEntityDisplayName(entityType?.name) || entity.name || entity.description || 'Unknown'
    }

    // Fallback in case none of the conditions match
    return entity.name || 'Unknown'
  }
})

const entityTypeLabel = computed(() => displayName.value(props.entity))

const tooltip = useTooltip({
  selector: `#entity-icon-${state.randUuid}`,
  content: entityTypeLabel,
})
</script>

<template>
  <div
    :id="`entity-icon-${state.randUuid}`"
    :class="`flex items-center justify-center flex-shrink-0 rounded ${props.class}`"
    :style="{
      width: size * 4 + 'px',
      height: size * 4 + 'px',
      backgroundColor: getBackgroundColorForEntityTypeColor(iconColor(props.entity) ?? '#181A4E'),
    }"
    @mouseover="tooltip.setUp"
    @mouseleave="tooltip.destroy"
  >
    <CIcon
      :name="icon(props.entity)"
      :style="{ width: size * 2 + 'px', height: size * 2 + 'px', color: `${iconColor(props.entity)} !important` }"
    />
  </div>
</template>
