import Echo from 'laravel-echo'
import { PusherChannel } from 'laravel-echo/dist/channel'
import { BroadcastAuthResponse } from '~/types/auth'
import { Callback } from '~/types/utility'
import { getPusherAppKey } from '.'

enum BroadCaster {
  Pusher = 'pusher',
  Reverb = 'reverb',
}

function mapStringToBroadcaster(broadcaster?: string): BroadCaster {
  if (broadcaster === 'reverb') {
    return BroadCaster.Reverb
  }

  return BroadCaster.Pusher
}

type EchoOptions = ConstructorParameters<typeof Echo>[0]

export function getSocketConfig(
  authorizer: (channelName: PusherChannel) => {
    authorize: (socketId: string, callback: Callback<BroadcastAuthResponse>) => Promise<void>
  },
): EchoOptions {
  const broadcaster = mapStringToBroadcaster(import.meta.env.VITE_BROADCAST_DRIVER)

  const baseOptions: Partial<EchoOptions> & { broadcaster: EchoOptions['broadcaster'] } = {
    broadcaster,
    forceTLS: (import.meta.env.VITE_WS_SCHEME ?? 'https') === 'https',
    authorizer,
  }

  if (broadcaster === BroadCaster.Reverb) {
    return {
      ...baseOptions,
      key: import.meta.env.VITE_WS_APP_KEY,
      wsHost: import.meta.env.VITE_WS_HOST,
      wsPort: import.meta.env.VITE_WS_PORT,
      wssPort: import.meta.env.VITE_WS_PORT,
      enabledTransports: ['ws', 'wss'],
    }
  }

  return {
    ...baseOptions,
    key: getPusherAppKey(),
    cluster: 'eu',
    transports: ['websocket', 'polling', 'flashsocket'],
  }
}
