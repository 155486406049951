<script setup lang="ts">
import tippy from 'tippy.js'
import { ref } from 'vue'
import { useApiRequest } from '~/composables/useApiRequest'
import { useInViewport } from '~/composables/useInViewport'
import { BomReadiness } from '~/types/view-elements'

const props = defineProps<{
  id: string
}>()

const progressData = ref<BomReadiness[] | null>(null)

const totalInstances = computed(() => {
  return progressData.value?.reduce((sum, item) => sum + item.total_instances, 0) ?? 0
})

const totalDone = computed(() => {
  return progressData.value?.reduce((sum, item) => sum + item.statuses.done, 0) ?? 0
})

const progress = computed(() => {
  const total = totalInstances.value
  const done = totalDone.value
  return total === 0 ? 0 : Number(((done / total) * 100).toFixed(2))
})

const progressByView = computed(() => {
  return (
    progressData.value?.map(item => {
      const total = item.total_instances ?? 0
      const done = item.statuses.done ?? 0
      return {
        name: item.view.name,
        value: total === 0 ? 0 : Number(((done / total) * 100).toFixed(2)),
      }
    }) ?? []
  )
})

const request = useApiRequest(async api => {
  const { data } = await api.getBomReadiness(props.id)
  progressData.value = data
  await nextTick()
  setupTooltip()
})

const componentRef = ref(null)
useInViewport(componentRef, {
  onEnter: () => {
    if (progressData.value === null) {
      request.runRequest()
    }
  },
})

function setupTooltip() {
  const template = document.getElementById(`tooltip-content-${props.id}`)
  tippy(`#bom-readiness-${props.id}`, {
    content: template?.innerHTML,
    allowHTML: true,
  })
}
</script>
<template>
  <div :id="`tooltip-content-${id}`" class="hidden">
    <div class="text-center">
      <div v-for="item in progressByView" :key="item.name">
        {{ item.name }}: <strong>{{ item.value }}%</strong>
      </div>
    </div>
  </div>

  <div
    ref="componentRef"
    :id="`bom-readiness-${id}`"
    class="bg-gray-50 rounded w-full flex items-center justify-center py-1 px-2 gap-2"
  >
    <div class="relative w-full h-1 bg-blue-100 rounded">
      <div
        class="absolute bg-blue-500 h-full rounded transition-[width] duration-300 ease-out"
        :style="{ width: `${progress}%` }"
      />
    </div>
    <div v-if="progressData === null" class="h-[20px]">
      <img height="20px" width="20px" src="/assets/loading-dots.svg" />
    </div>
    <CText v-else size="xs" color="gray" type="spot" class="line-clamp-1 flex-shrink-0"> {{ progress }}% </CText>
  </div>
</template>
