export enum TextSize {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
  xxxl = '3xl',
  xxxxl = '4xl',
  xxxxxl = '5xl',
}

export enum FontWeight {
  regular = 'regular',
  semibold = 'semibold',
  bold = 'bold',
}

export enum Colors {
  neutral = 'neutral',
  primary = 'primary',
  success = 'success',
  error = 'error',
  warning = 'warning',
  gray = 'gray',
  blue = 'blue',
  red = 'red',
  purple = 'purple',
  yellow = 'yellow',
  green = 'green',
  pink = 'pink',
  orange = 'orange',
}

export enum TextType {
  inverted = 'inverted',
  primary = 'primary',
  secondary = 'secondary',
  spot = 'spot',
}

export enum Spacing {
  xxxxs = 'xxxxs', // 2px
  xxxs = 'xxxs', // 4px
  xxs = 'xxs', // 6px
  xs = 'xs', // 8px
  s = 's', // 12px
  m = 'm', // 16px
  l = 'l', // 24px
  xl = 'xl', // 32px
  xxl = 'xxl', // 40px
}
