import type { Tool } from '@mistralai/mistralai/models/components/tool'
import { findBomDescription, findBomName, findBomSchema, findBomShared } from '../../sharedTools/findBom'

export const findBomTool: Tool = {
  type: 'function',
  function: {
    name: findBomName,
    description: findBomDescription,
    parameters: findBomSchema,
  },
}

export async function findBom() {
  return findBomShared()
}
