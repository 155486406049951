import type { z } from 'zod'

export interface AIModel {
  id: string
  name: string
  contextWindow: number
  supportsJson: boolean
}

export interface AICompletionOptions {
  model: string
  systemPrompt?: string
  userPrompt: string
  temperature?: number
  maxTokens?: number
  stream?: boolean
  schema?: z.ZodType
}

export interface AICompletionResponse {
  content: string | object
  model: string
  provider: string
  usage?: {
    promptTokens: number
    completionTokens: number
    totalTokens: number
  }
}

export interface AIStreamChunk {
  type: 'text' | 'json' | 'error'
  content: string
  done?: boolean
  parsed?: unknown
}

export interface AIClient {
  readonly provider: string
  readonly models: AIModel[]

  getCompletion(options: AICompletionOptions): Promise<AICompletionResponse>
  getAvailableModels(): AIModel[]
  getStream(options: AICompletionOptions): AsyncGenerator<AIStreamChunk>
}

export enum AIFEature {
  CREATE_BOM = 'createBomCSV',
  AI_BOM_SEARCH = 'aiBomSearch',
}

export const DEFAULT_AI_MODELS: Record<AIFEature, { provider: string; model: string }> = {
  [AIFEature.CREATE_BOM]: { provider: 'openai', model: 'gpt-4o-2024-08-06' },
  [AIFEature.AI_BOM_SEARCH]: { provider: 'openai', model: 'gpt-4o-2024-08-06' },
}

export const ENV_VAR_FOR_FEATURE: Record<AIFEature, { provider: string; model: string }> = {
  [AIFEature.CREATE_BOM]: {
    provider: 'VITE_CREATE_BOM_AI_PROVIDER' as const,
    model: 'VITE_CREATE_BOM_AI_MODEL' as const,
  },
  [AIFEature.AI_BOM_SEARCH]: {
    provider: 'VITE_AI_BOM_SEARCH_PROVIDER' as const,
    model: 'VITE_AI_BOM_SEARCH_MODEL' as const,
  },
}
