<script setup lang="ts">
const { t } = useI18n()
import { debounce } from 'lodash'
import { useDialog } from '~/composables/useDialog'
import { useIntersectionObserver } from '~/composables/useIntersectionObserver'
import { useKeyupHandler } from '~/composables/useKeyupHandler'
import { useSort } from '~/composables/useSort'
import { isAIenabledForUser, isForvia } from '~/helpers/growthbook/growthbook'
import { growthBookKey } from '~/modules/growthbook'
import { apiStore } from '~/stores/api'
import { configStore } from '~/stores/configs'
import { entityTypesStore } from '~/stores/entity_types'
import { Notification, notificationsStore as useNotifications } from '~/stores/notifications'
import { userStore } from '~/stores/user'
import { viewsStore } from '~/stores/views'
import { unwrapApiErrors } from '~/types/api'
import type { ToastList } from '~/types/toast'
import { Bom } from '~/types/view-elements'
import { Status } from '~/utils/entity'
import { unwrapRouteParam } from '~/utils/route'
import BomStatusFilter from './BomStatusFilter.vue'

const growthBookInjectable = inject(growthBookKey)

const api = apiStore().getApiClient
const notificationsStore = useNotifications()
const router = useRouter()
const route = useRoute()

const modal = useDialog()

const state = reactive<{
  boms: Bom[]
  isLoading: boolean
  isLoadingMore: boolean
  currentPage: number
  perPage: number
  hasFetchingNext: boolean
  isReachedAtLast: boolean
  hasAddBomModalOpen: boolean
  hasAddingBom: boolean
  search: string
  status: string
  toast?: ToastList
  selectedIds: Set<string>
  hasDeleteBomModalOpen: boolean
  isDeleting: boolean
  isImportModalOpen: boolean
  isForvia: boolean
  isAIEnabled: boolean
  imagePath: string | null
  isFullscreenImageModalOpen: boolean
  selectAll: boolean
}>({
  boms: [],
  isLoading: false,
  isLoadingMore: false,
  currentPage: 1,
  perPage: 20,
  hasFetchingNext: false,
  isReachedAtLast: false,
  hasAddBomModalOpen: false,
  hasAddingBom: false,
  search: '',
  status: unwrapRouteParam(route.query.status) || '',
  toast: inject<ToastList>('toast'),
  selectedIds: new Set(),
  hasDeleteBomModalOpen: false,
  isDeleting: false,
  isImportModalOpen: false,
  isForvia: false,
  isAIEnabled: false,
  imagePath: null as null | string,
  isFullscreenImageModalOpen: false,
  selectAll: false,
})

const canCreateBom = computed(() => {
  return userStore()?.user?.roles.includes('admin') || userStore()?.user?.roles.includes('project_manager')
})

async function loadPaginatedData(page: number = 1) {
  if (state.hasFetchingNext || state.isReachedAtLast) return

  if (page === 1) {
    state.isLoading = true
  } else {
    state.isLoadingMore = true
  }

  state.hasFetchingNext = true

  try {
    const [response] = await Promise.all([
      await api.getBoms(
        page,
        state.perPage,
        {
          ...(state.search ? { name: state.search } : {}),
          ...(state.status ? { status: state.status } : {}),
          ...(sort.sortQueryParam ? { sort: sort.sortQueryParam.value } : {}),
        },
        ['readinessSnapshots'],
      ),
      viewsStore().loadViews(),
      entityTypesStore().loadEntityTypes(),
    ])

    if (page === 1) {
      state.boms = response.data
    } else {
      state.boms.push(...response.data)
    }

    if (response.meta.current_page == response.meta.last_page) state.isReachedAtLast = true

    if (state.boms.length <= 0) {
      state.selectedIds = new Set()
      state.selectAll = false
    }

    state.currentPage += 1
    state.hasFetchingNext = false
  } catch (error) {
    router.push('/')
    const errorMessage = unwrapApiErrors(error)
    state.toast?.error(t('global.error'), errorMessage)
  } finally {
    if (page === 1) {
      state.isLoading = false
    } else {
      state.isLoadingMore = false
    }
  }
}

function goToBomDetails(row: Bom) {
  if (!row.auth.can.view) return

  const view_id = unwrapRouteParam(route.query.view_id) || configStore().getClientDefaultView
  const view_type =
    (unwrapRouteParam(route.query.view_type) as 'tree' | 'graph' | 'table') ||
    configStore().getClientDefaultViewDisplayType
  viewsStore().setDisplayType(view_type)

  router.push({
    path: `/boms/${row.id}`,
    query: {
      ...route.query,
      view_id,
      view_type,
      bom_tab: 1,
    },
  })
}

function openAddBomModal() {
  state.hasAddBomModalOpen = true
}

function closeAddBomModal() {
  state.hasAddBomModalOpen = false
}

function createBom(data: Bom) {
  state.boms.unshift(data)
}

const searchTextChanged = debounce(async () => {
  state.currentPage = 1
  state.isReachedAtLast = false
  state.boms = []
  state.selectAll = false
  state.selectedIds = new Set()
  await loadPaginatedData(1)
}, 400)

async function filterStatusChanged(status: string) {
  state.status = status
  state.currentPage = 1
  state.isReachedAtLast = false
  state.boms = []
  state.selectAll = false
  state.selectedIds = new Set()
  await loadPaginatedData(1)
}

function openDeleteBomModal() {
  state.hasDeleteBomModalOpen = true
}

function closeDeleteBomModal() {
  state.hasDeleteBomModalOpen = false
}

async function deleteBoms() {
  if (state.selectedIds.size === 0) {
    state.toast?.error(t('global.error'), t('boms.no_selected'))
    return
  }

  try {
    state.isDeleting = true

    for (const bomId of Array.from(state.selectedIds)) {
      const idx = state.boms.findIndex(e => e.id === bomId)

      if (idx !== -1) {
        await api.removeBom(bomId)
        state.boms.splice(idx, 1)
      }
    }

    state.selectedIds.clear()
    state.selectAll = false

    state.toast?.success(t('global.success'), t('boms.delete_success'))
    closeDeleteBomModal()
  } catch (error) {
    const errorMessage = unwrapApiErrors(error)
    state.toast?.error(t('global.error'), errorMessage)
  } finally {
    state.isDeleting = false
  }
}

function openImportModal() {
  state.isImportModalOpen = true
}

function closeImportModal() {
  state.isImportModalOpen = false
}

function openFullScreenImageModal(imagePath: string) {
  if (!imagePath) {
    return
  }

  state.imagePath = imagePath
  state.isFullscreenImageModalOpen = true
}

function closeFullScreenImageModal() {
  state.isFullscreenImageModalOpen = false
}

onBeforeMount(async () => {
  const growthBook = await growthBookInjectable?.init()
  state.isForvia = (await isForvia(growthBook)) || false
  const userId = userStore().getUser?.id
  state.isAIEnabled =
    (!!userId &&
      !!growthBook &&
      import.meta.env.VITE_ENABLE_AI === 'true' &&
      (await isAIenabledForUser(growthBook, userId))) ??
    false
})

const handleEscapeKey = () => {
  closeAddBomModal()
  closeDeleteBomModal()
}

useKeyupHandler(handleEscapeKey)

function onNotificationReceived(notification: Notification) {
  if (notification.type.includes('ETLCompleted')) {
    state.currentPage = 1
    state.isReachedAtLast = false
    state.boms = []
    state.selectAll = false
    state.selectedIds = new Set()
    loadPaginatedData(1)
  }
}

const { registerObserver, unRegisterObserver } = useIntersectionObserver('#issueObserver', () =>
  loadPaginatedData(state.currentPage),
)

onMounted(async () => {
  notificationsStore.addListener(onNotificationReceived)
  await loadPaginatedData(1)
})

watch(
  () => state.boms.length,
  () => {
    registerObserver()
  },
)

watch(
  () => state.isReachedAtLast,
  newVal => {
    if (newVal) {
      unRegisterObserver()
    }
  },
)

onBeforeUnmount(() => {
  notificationsStore.removeListener(onNotificationReceived)
  unRegisterObserver()
})

const sort = useSort(
  [
    { key: 'name', defaultOrder: 'asc', queryParam: 'name' },
    { key: 'updated_at', defaultOrder: 'desc', queryParam: 'updated_at' },
  ],
  () => {
    state.currentPage = 1
    state.isReachedAtLast = false
    state.boms = []
    state.selectAll = false
    state.selectedIds = new Set()
    loadPaginatedData(1)
  },
  { key: 'updated_at', order: 'desc' },
)

const columns = [
  {
    key: 'name',
    label: t('global.name'),
    sortable: true,
  },
  {
    key: 'description',
    label: t('global.description'),
    grow: true,
  },
  {
    key: 'progress',
    label: t('global.progress'),
    maxWidth: 168,
  },
  {
    key: 'updated_at',
    label: t('global.last_updated'),
    type: 'date',
    noWrap: true,
    maxWidth: 142,
    sortable: true,
  },
  {
    key: 'status',
    label: t('global.status'),
    noWrap: true,
    maxWidth: 122,
  },
]

function onRowselected(row: Bom | boolean) {
  if (typeof row === 'boolean') {
    state.selectAll = row
    state.selectedIds = row ? new Set(state.boms.map(bom => bom.id)) : new Set()
  } else {
    if (state.selectedIds.has(row.id)) {
      state.selectedIds.delete(row.id)
    } else {
      state.selectedIds.add(row.id)
    }
  }
}

const sortProp = computed(() => ({
  key: sort.sortKey.value,
  order: sort.order.value,
}))
</script>

<template>
  <div class="flex flex-col flex-grow">
    <div class="w-full flex items-center justify-between">
      <div class="flex items-center relative">
        <BomStatusFilter :options="Status" :value="state.status" @update:model-value="filterStatusChanged" />
        <div class="pl-2">
          <OSearchBar
            v-model="state.search"
            :placeholder="`${$t('global.search')}`"
            class="!w-96"
            @update:modelValue="searchTextChanged"
          />
        </div>
      </div>
      <div class="flex">
        <button
          class="btn-danger flex items-center gap-2 first:mr-2"
          v-if="state.selectedIds.size > 0"
          @click="openDeleteBomModal"
        >
          <CIcon name="trash-can" class="w-4 h-4" />
          {{ $t('global.delete') }}
        </button>

        <div class="flex" v-if="canCreateBom">
          <button
            class="btn-secondary btn-purple gap-1.5 h-full h-fit mr-2"
            @click="modal.open"
            v-if="state.isAIEnabled"
          >
            <CIcon name="magic-wand" class="w-4 h-4" />
            {{ $t('global.generate') }}
          </button>
          <button class="btn-secondary gap-1.5 h-full h-fit mr-2" @click="openImportModal">
            <CIcon name="download" class="w-4 h-4" />
            {{ $t('global.import') }}
          </button>
          <button class="btn-primary h-full" @click="openAddBomModal">
            <CIcon name="add" class="w-4 h-4" />
            {{ $t('global.new') }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex-grow mt-8 h-full">
      <CTable
        :columns="columns"
        :rows="state.boms"
        @row-click="goToBomDetails"
        @row-select="onRowselected"
        :is-loading="state.isLoading"
        :is-loading-more="state.isLoadingMore"
        :empty-message="t('boms.no_found')"
        :no-border="true"
        :sort="sortProp"
        @on-sort-change="sort.setSort"
      >
        <template #name="{ item: bom }">
          <div class="flex items-center gap-2 -my-4 py-1">
            <div
              class="col-span-1 flex-shrink-0 flex items-center overflow-hidden rounded"
              @click.stop.prevent="openFullScreenImageModal(bom?.image)"
            >
              <img v-if="bom?.image" :src="bom.image" class="object-cover w-auto h-9 w-9 aspect-square" />
              <img
                v-else
                src="/assets/logo_small_muted.svg"
                class="object-cover w-auto h-9 w-9 aspect-square !text-gray-600 rounded border border-gray-200 bg-gray-50 p-1.5"
              />
            </div>

            <div class="flex flex-col justify-center">
              <CText size="s" color="neutral" type="primary" class="line-clamp-1 underline">
                {{ bom.name }}
              </CText>
              <CText v-if="bom.reference" size="xs" color="neutral" type="spot" class="line-clamp-1">{{
                bom.reference
              }}</CText>
            </div>
          </div>
        </template>

        <template #progress="{ item: bom }">
          <BomReadinessSnapshot :id="bom.id" />
        </template>

        <template #status="{ item: bom }">
          <div class="col-span-1 flex items-center gap-2">
            <PublishingStatusTag :status="bom.status" />
          </div>
        </template>
        <template #observer>
          <div class="h-4" id="issueObserver" />
        </template>
      </CTable>
    </div>

    <OModal :open="state.hasDeleteBomModalOpen">
      <template #content>
        <div class="flex flex-col gap-1">
          <h2 class="text-xl font-semibold">
            {{ $t('global.confirm_delete') }}
          </h2>
          <p>
            {{ $t('boms.delete_title') }}
          </p>
          <span
            class="mt-4 rounded-md bg-red-50 px-2 py-1 text-s font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
          >
            <b>Warning:&nbsp;</b>{{ $t('boms.delete_description') }}
          </span>
        </div>
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-secondary" :disabled="state.isDeleting" @click="closeDeleteBomModal">
            {{ t('global.cancel') }}
          </button>
          <button class="btn-danger" autofocus :disabled="state.isDeleting" @click="deleteBoms">
            {{ state.isDeleting ? t('global.deleting') : t('global.delete') }}
          </button>
        </div>
      </template>
    </OModal>

    <EntityCreationModal
      :open="state.hasAddBomModalOpen"
      default-type="Bom"
      :isBomPage="true"
      @close="closeAddBomModal"
      @create="createBom"
    />

    <GraphElementImportModal
      :open="state.isImportModalOpen"
      @close="closeImportModal"
      @import-success="loadPaginatedData"
      import-from="bom"
    />

    <!-- Image modal -->
    <OModal :open="state.isFullscreenImageModalOpen" @close="closeFullScreenImageModal">
      <template #content>
        <img :src="state.imagePath ?? undefined" />
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-primary w-full" @click="closeFullScreenImageModal">
            {{ $t('global.close') }}
          </button>
        </div>
      </template>
    </OModal>

    <!-- Create Bom with AI Modal -->
    <CreateAIBomModal :open="modal.isOpen.value" @close="modal.close" />
  </div>
</template>
