<script setup lang="ts">
import { Switch } from '@headlessui/vue'
import type { Attribute } from '~/types/view-elements'

const props = defineProps({
  modelValue: {
    type: Array<Attribute>,
    required: true,
  },
  disabledForm: {
    type: Boolean,
    required: false,
    default: false,
  },
  canHideNullAttributes: {
    type: Boolean,
    required: false,
    default: true,
  },
  wrapperClass: {
    type: String,
    required: false,
    default: '',
  },
  attributeClass: {
    type: String,
    required: false,
    default: '',
  },
  labels: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const emit = defineEmits<{
  'update:modelValue': [value: Attribute[]]
}>()

const types = ['STRING', 'NUMBER', 'BOOLEAN', 'DATE', 'SELECT', 'MULTISELECT', 'LIST']

const data = reactive({
  attributes: [] as Attribute[],
})

const state = reactive({
  searchAttributes: '',
  isShowingNullAttributes: false,
})

const canShowEmptyAttributes = computed(() => {
  if (!props.canHideNullAttributes) {
    return true
  }
  return state.isShowingNullAttributes
})

const filteredAttributes = computed(() => {
  if (!Array.isArray(props.modelValue)) return []

  if (!state.searchAttributes) return props.modelValue

  return props.modelValue.filter(attribute => {
    if (attribute?.value && typeof attribute.value === 'string')
      return (
        attribute.name.toLowerCase().includes(state.searchAttributes.toLowerCase()) ||
        attribute.value.toLowerCase().includes(state.searchAttributes.toLowerCase())
      )
    else return attribute.name.toLowerCase().includes(state.searchAttributes.toLowerCase())
  })
})

function updateModelValue() {
  emit('update:modelValue', data.attributes)
}

function toggleNullAttributesVisibility() {
  state.isShowingNullAttributes = !state.isShowingNullAttributes
}

function loadData() {
  data.attributes = props.modelValue
  if (data.attributes && data.attributes.length > 0) {
    data.attributes = data.attributes.map(attribute => {
      attribute.name = attribute.name
      if (attribute.type === 'DATE' && attribute.value) {
        attribute.value = new Date(attribute.value).toISOString().substring(0, 10)
      }
      return attribute
    })
  }

  // data.attributes = types.map((type: any, index: number) => ({
  //   uuid: '',
  //   domains: [],
  //   name: `Default ${index}`,
  //   type,
  //   value: type === 'BOOLEAN' ? false : type === 'DATE' ? new Date().toISOString().substring(0, 10) : '',
  // }))
}

onBeforeMount(() => {
  loadData()
})

watch(
  () => props.modelValue,
  () => {
    loadData()
  },
)
</script>

<template>
  <div class="flex flex-col gap-3">
    <div class="flex gap-2">
      <slot name="header" />
      <div class="flex items-center gap-1 text-sm text-gray-500">
        <CIcon name="search" class="w-3.5 h-3.5" />
        <input v-model="state.searchAttributes" :placeholder="$t('global.type_to_search')" />
      </div>
      <button
        v-if="props.canHideNullAttributes"
        class="btn-secondary gap-1.5 ml-auto"
        @click="toggleNullAttributesVisibility"
      >
        <CIcon :name="state.isShowingNullAttributes ? 'view' : 'view-off'" />
        {{ state.isShowingNullAttributes ? $t('attributes.hide_empty') : $t('attributes.show_empty') }}
      </button>
    </div>
    <div :class="props.wrapperClass">
      <template v-for="(attribute, index) in data.attributes" :key="attribute.id">
        <div
          v-if="
            (canShowEmptyAttributes || attribute.value) &&
            filteredAttributes.find(filteredAttribute => filteredAttribute.id === attribute.id)
          "
          class="flex flex-1 flex-col justify-center gap-1"
          :class="attributeClass"
        >
          <div class="font-medium text-sm text-gray-500">{{ attribute.name }}</div>

          <template v-if="attribute.type === 'BOOLEAN'">
            <Switch
              v-model="attribute.value"
              :disabled="props.disabledForm"
              :class="attribute.value ? 'bg-primary' : 'bg-gray-200'"
              class="relative inline-flex h-6 w-11 items-center rounded-full mt-2"
            >
              <span
                :class="attribute.value ? 'translate-x-6' : 'translate-x-1'"
                class="inline-block h-4 w-4 transform rounded-full bg-white transition"
              />
            </Switch>
          </template>

          <template v-else-if="props.disabledForm">
            <p class="text-sm text-gray-900">{{ attribute.value || '‏‏‎ ‎' }}</p>
          </template>

          <template v-else>
            <FormKit
              v-if="attribute.type === 'STRING'"
              v-model="attribute.value"
              type="text"
              :name="attribute.name"
              :placeholder="$t('global.value')"
              :disabled="props.disabledForm"
              @input="updateModelValue"
            />
            <FormKit
              v-if="attribute.type === 'NUMBER'"
              v-model="attribute.value"
              type="number"
              :name="attribute.name"
              :placeholder="$t('global.value')"
              :disabled="props.disabledForm"
              @input="updateModelValue"
            />

            <FormKit
              v-if="attribute.type === 'DATE'"
              v-model="attribute.value"
              type="date"
              :name="attribute.name"
              :placeholder="$t('global.value')"
              input-class="!py-[7px]"
              :disabled="props.disabledForm"
              @input="updateModelValue"
            />
            <FormKit
              v-else-if="attribute.type === 'SELECT'"
              v-model="attribute.value"
              type="select"
              :name="attribute.name"
              :placeholder="$t('global.value')"
              input-class="!py-[7px]"
              :disabled="props.disabledForm"
              :options="
                attribute?.options || [
                  { label: $t('global.no_options_defined'), value: null, attrs: { disabled: true } },
                ]
              "
              @input="updateModelValue"
              suffix-icon="carbon-chevron-sort"
            />
            <FormKit
              v-else-if="attribute.type === 'MULTISELECT'"
              v-model="attribute.value"
              type="autocomplete"
              :name="attribute.name"
              :placeholder="$t('global.value')"
              multiple="true"
              open-on-click="true"
              selection-appearance="option"
              input-class="!py-[7px]"
              :options="
                attribute?.options || [
                  { label: $t('global.no_options_defined'), value: null, attrs: { disabled: true } },
                ]
              "
              suffix-icon="carbon-chevron-sort"
            />
            <FormKit
              v-else-if="attribute.type === 'LIST'"
              v-model="attribute.value"
              type="taglist"
              :name="attribute.name"
              :placeholder="$t('global.value')"
              allow-new-values="true"
              :options="
                attribute?.options || [
                  { label: $t('global.no_options_defined'), value: null, attrs: { disabled: true } },
                ]
              "
              suffix-icon="carbon-chevron-sort"
            />
          </template>
        </div>
      </template>
    </div>
    <p v-if="filteredAttributes.length === 0" class="text-sm text-gray-400 col-span-3">
      {{ $t('attributes.no_attributes') }}
    </p>
  </div>
</template>
