<script setup lang="ts">
import { useStyles } from './composables/useStyles'
import { Colors, FontWeight, TextSize, TextType } from './types'

const props = withDefaults(
  defineProps<{
    size?: `${TextSize}`
    weight?: `${FontWeight}`
    muted?: boolean // deprecated - kept for backwards compatibility
    mutedDark?: boolean // deprecated - kept for backwards compatibility
    whiteText?: boolean // deprecated - kept for backwards compatibility
    elevation?: 0 | 1 | 2 | '0' | '1' | '2'
    color?: `${Colors}`
    type?: `${TextType}`
  }>(),
  {
    size: TextSize.m as const,
    weight: FontWeight.regular as const,
    muted: false,
    mutedDark: false,
    whiteText: false,
    type: TextType.primary as const,
  },
)

const styles = useStyles(props)
</script>

<template>
  <p :class="[styles.fontSize.value, styles.fontWeight.value, styles.fontColor.value]">
    <slot></slot>
  </p>
</template>
