import { ChatCompletionResponse } from '@mistralai/mistralai/models/components/chatcompletionresponse.js'
import { ChatCompletionStreamRequest } from '@mistralai/mistralai/models/components/chatcompletionstreamrequest.js'
import { CompletionChunk } from '@mistralai/mistralai/models/components/completionchunk'
import { CompletionEvent } from '@mistralai/mistralai/models/components/completionevent.js'
import { BaseAIClient } from '../../baseClient'
import { AIClient, AICompletionOptions, AICompletionResponse, AIModel, AIStreamChunk } from '../../interface'
import { toolFunctions, tools } from './tools'

const SHOULD_YIELD_TOOLS = ['selectBoms', 'createBomCSV']

function isErrorChunk(
  chunk: CompletionChunk | { type: 'error'; content: string },
): chunk is { type: 'error'; content: string } {
  return 'type' in chunk && chunk.type === 'error'
}

export class MistralClient implements AIClient {
  private client: BaseAIClient<ChatCompletionStreamRequest, CompletionEvent, ChatCompletionResponse>
  readonly provider = 'mistral'
  readonly models: AIModel[] = [
    {
      id: 'mistral-large-latest',
      name: 'Mistral Large',
      contextWindow: 32768,
      supportsJson: true,
    },
    {
      id: 'mistral-medium-latest',
      name: 'Mistral Medium',
      contextWindow: 32768,
      supportsJson: true,
    },
    {
      id: 'mistral-small-latest',
      name: 'Mistral Small',
      contextWindow: 32768,
      supportsJson: true,
    },
  ]

  constructor() {
    this.client = new BaseAIClient<ChatCompletionStreamRequest, CompletionEvent, ChatCompletionResponse>({
      url: '/api/mistral/chat',
    })
  }

  getAvailableModels(): AIModel[] {
    return this.models
  }

  async getCompletion(options: AICompletionOptions): Promise<AICompletionResponse> {
    const response = await this.client.getCompletion({
      model: options.model,
      messages: [
        ...(options.systemPrompt ? [{ role: 'system' as const, content: options.systemPrompt }] : []),
        { role: 'user', content: options.userPrompt },
      ],
      temperature: options.temperature,
      stream: false,
    })

    const responseContent = response.choices?.[0].message.content ?? ''

    const content = Array.isArray(responseContent) ? responseContent.join('\n') : responseContent

    return {
      content,
      model: response.model,
      provider: this.provider,
      usage: {
        promptTokens: response.usage.promptTokens,
        completionTokens: response.usage.completionTokens,
        totalTokens: response.usage.totalTokens,
      },
    }
  }

  async *getStream(
    options: AICompletionOptions & { messages?: ChatCompletionStreamRequest['messages'] },
  ): AsyncGenerator<AIStreamChunk, void, unknown> {
    const messages = options.messages ?? [
      ...(options.systemPrompt ? [{ role: 'system' as const, content: options.systemPrompt }] : []),
      { role: 'user', content: options.userPrompt },
    ]

    const stream = await this.client.messages({
      model: options.model,
      messages,
      temperature: options.temperature,
      stream: true,
      tools,
      toolChoice: 'required',
    })

    let currentText = ''
    let currentToolCall: { index: number; name: string; id: string; arguments: string; shouldYield: boolean } | null =
      null

    for await (const event of stream) {
      const chunk = event.data

      if (!chunk) return

      if (isErrorChunk(chunk)) {
        yield chunk
        return
      }

      const delta = chunk.choices?.[0].delta

      if (!delta) {
        return
      }

      if (delta.toolCalls?.[0]) {
        const toolCall = delta.toolCalls[0]
        const args =
          typeof toolCall.function.arguments === 'string'
            ? toolCall.function.arguments
            : JSON.stringify(toolCall.function.arguments)

        if (!currentToolCall) {
          currentToolCall = {
            index: chunk.choices[0].index,
            name: toolCall.function.name,
            id: toolCall.id ?? '',
            arguments: args,
            shouldYield: SHOULD_YIELD_TOOLS.includes(toolCall.function.name),
          }
        } else {
          currentToolCall.index = chunk.choices[0].index
          currentToolCall.arguments = args
        }
      }

      if (currentToolCall && chunk.choices[0].finishReason === 'tool_calls') {
        const toolResult = await this.executeToolCall(
          currentToolCall.name,
          JSON.parse(currentToolCall.arguments ?? '{}'),
        )

        const newMessages = []

        if (currentToolCall.shouldYield) {
          yield {
            type: 'json',
            content: toolResult,
            parsed: JSON.parse(toolResult),
          }
        } else {
          newMessages.push(
            ...messages,
            {
              role: 'assistant' as const,
              content: '',
              toolCalls: [
                {
                  id: currentToolCall.id,
                  function: {
                    name: currentToolCall.name,
                    arguments: JSON.parse(currentToolCall.arguments ?? '{}'),
                  },
                },
              ],
            },
            {
              role: 'tool' as const,
              name: currentToolCall.name,
              content: toolResult,
              toolCallId: currentToolCall.id,
            },
          )
        }

        if (newMessages.length) {
          const toolResponse = this.getStream({
            ...options,
            messages: newMessages,
          })
          yield* toolResponse
          return
        }
      }

      if (delta.content) {
        const content = typeof delta.content === 'string' ? delta.content : JSON.stringify(delta.content)
        currentText += content

        yield {
          type: 'text',
          content: currentText,
        }

        if (chunk.choices[0].finishReason === 'stop') {
          // If we have a schema, try to parse the accumulated text as JSON
          if (currentToolCall && options.schema && currentText) {
            try {
              const jsonContent = JSON.parse(currentText)
              const parsed = options.schema.parse(jsonContent)

              yield {
                type: 'json',
                content: currentText,
                parsed,
                done: true,
              }
            } catch (error) {
              yield {
                type: 'error',
                content: error instanceof Error ? error.message : 'An unexpected error occurred',
                done: true,
              }
            } finally {
              currentText = ''
              currentToolCall = null
            }
          } else {
            yield {
              type: 'text',
              content: '',
              done: true,
            }
          }
        }
      }
    }
  }

  private async executeToolCall(name: string, args: unknown): Promise<string> {
    const func = toolFunctions[name as keyof typeof toolFunctions]
    if (!func) {
      throw new Error(`Unknown tool: ${name}`)
    }
    // @ts-expect-error - just invokes the right tool
    return func(args)
  }
}
