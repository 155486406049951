<script setup lang="ts">
import { apiStore } from '~/stores/api'

const api = apiStore().getApiClient

const data = reactive({
  widget: {
    openFeedbacks: 0,
    criticalFeedbacks: 0,
    highFeedbacks: 0,
    mediumFeedbacks: 0,
    lowFeedbacks: 0,
    noneFeedbacks: 0,
  },
})

const state = reactive({
  isLoading: true,
})

function loadData() {
  api
    .getTodosCount()
    .then(res => {
      data.widget.openFeedbacks = res.unresolved.total
      data.widget.criticalFeedbacks = res.unresolved.critical
      data.widget.highFeedbacks = res.unresolved.high
      data.widget.mediumFeedbacks = res.unresolved.medium
      data.widget.lowFeedbacks = res.unresolved.low
      data.widget.noneFeedbacks = res.unresolved.none
    })
    .finally(() => {
      state.isLoading = false
    })
}

onMounted(() => {
  loadData()
})
</script>

<template>
  <!-- this is hidden as we're using it in the index which already includes the header -->
  <!-- <div class="flex flex-col h-full bg-gray-25">
    <div class="flex w-full pl-4 ">
      <div class="flex flex-col container gap-1 pt-4 pb-2">
        <div class="flex gap-2 text-sm ml-10">
          <router-link
            to="/"
            class="text-gray-400"
          >
            {{ $t("global.dashboard") }}
          </router-link>
        </div>
        <div class="flex justify-between">
          <div class="flex items-center gap-4 text-2xl text-blue-900">
            <button class="text-lg bg-white rounded transition hover:bg-gray-25 hover:text-blue-400" @click="router.go(-1)">
              <CIcon name="chevron-left" />
            </button>
            {{ $t("global.dashboard") }}
          </div>
        </div>
      </div>
    </div> -->
  <div v-if="!state.isLoading" class="p-4 md:p-6 pt-2 overflow-y-scroll flex flex-col gap-4 lg:mx-12">
    <div class="flex flex-col gap-2 mx-4">
      <!-------------------------WIDGET--------------------------------------------->
      <div class="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:w-auto w-full">
        <Widget
          :title="$t('global.total_assigned_unresolved_feedbacks')"
          :data="data.widget.openFeedbacks"
          icon="carbon-book"
          icon-color="text-purple-400"
          icon-background="bg-purple-100"
          data-testid="total-assigned-unresolved"
        />
        <Widget
          :title="$t('global.total_assigned_unresolved_critical_feedbacks')"
          :data="data.widget.criticalFeedbacks"
          icon="carbon-lightning"
          icon-color="text-red-700"
          icon-background="bg-red-50"
          data-testid="total-critical-unresolved"
        />
        <Widget
          :title="$t('global.total_assigned_unresolved_medium_feedbacks')"
          :data="data.widget.mediumFeedbacks"
          icon="carbon-warning-alt"
          icon-color="text-yellow-900"
          icon-background="bg-yellow-50"
          data-testid="total-medium-priority-unresolved"
        />
        <Widget
          :title="$t('global.total_assigned_unresolved_low_feedbacks')"
          :data="data.widget.lowFeedbacks"
          icon="carbon-information"
          icon-color="text-blue-600"
          icon-background="bg-blue-100"
          data-testid="total-low-priority-unresolved"
        />
        <Widget
          :title="$t('global.total_assigned_unresolved_none_feedbacks')"
          :data="data.widget.noneFeedbacks"
          icon="carbon-information"
          icon-color="text-blue-600"
          icon-background="bg-blue-100"
          data-testid="total-no-priority-unresolved"
        />
      </div>
    </div>
  </div>
  <Loader v-else />
  <!-- </div> -->
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
