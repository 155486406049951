import OpenAI from 'openai'

export interface BomImportData {
  nodes: {
    level: number
    reference: string
    name: string
    type: 'part' | 'assembly' | 'raw material'
    description: string
    uom: 'piece'
    quantity: number
  }[]
}

export const createBomCSVTool: OpenAI.ChatCompletionTool = {
  type: 'function',
  function: {
    name: 'createBomCSV',
    description: 'Create CSV output from the provided BOM data',
    parameters: {
      type: 'object',
      properties: {
        nodes: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              level: {
                type: 'number',
              },
              reference: {
                type: 'string',
              },
              name: {
                type: 'string',
              },
              type: {
                type: 'string',
              },
              description: {
                type: 'string',
              },
              uom: {
                type: 'string',
              },
              quantity: {
                type: 'number',
              },
            },
            required: ['level', 'reference', 'name', 'type', 'description', 'uom', 'quantity'],
          },
        },
      },
      required: ['nodes'],
    },
  },
}

export async function createBomCSV(input: BomImportData) {
  return JSON.stringify({ nodes: input.nodes })
}
