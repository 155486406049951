export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const colorClasses = [
  {
    neutral: {
      primary: 'text-gray-900',
      secondary: 'text-gray-700',
      spot: 'text-gray-600',
      border: 'border-gray-200',
      background: 'bg-gray-0',
      inverted: 'text-white',
    },
    gray: {
      primary: 'text-gray-900',
      secondary: 'text-gray-700',
      spot: 'text-gray-600',
      border: 'border-gray-200',
      background: 'bg-gray-0',
      inverted: 'text-white',
    },
  },
  {
    neutral: {
      primary: 'text-gray-900',
      secondary: 'text-gray-800',
      spot: 'text-gray-700',
      border: 'border-gray-300',
      background: 'bg-gray-50',
      inverted: 'text-white',
    },
    gray: {
      primary: 'text-gray-900',
      secondary: 'text-gray-800',
      spot: 'text-gray-700',
      border: 'border-gray-300',
      background: 'bg-gray-50',
      inverted: 'text-white',
    },
    primary: {
      primary: 'text-blue-900',
      secondary: 'text-blue-700',
      spot: 'text-blue-600',
      border: 'border-blue-200',
      background: 'bg-blue-50',
      inverted: 'text-white',
    },
    blue: {
      primary: 'text-blue-900',
      secondary: 'text-blue-700',
      spot: 'text-blue-600',
      border: 'border-blue-200',
      background: 'bg-blue-50',
      inverted: 'text-white',
    },
    success: {
      primary: 'text-green-900',
      secondary: 'text-green-700',
      spot: 'text-green-600',
      border: 'border-green-200',
      background: 'bg-green-50',
      inverted: 'text-white',
    },
    green: {
      primary: 'text-green-900',
      secondary: 'text-green-700',
      spot: 'text-green-600',
      border: 'border-green-200',
      background: 'bg-green-50',
      inverted: 'text-white',
    },
    error: {
      primary: 'text-red-900',
      secondary: 'text-red-700',
      spot: 'text-red-600',
      border: 'border-red-200',
      background: 'bg-red-50',
      inverted: 'text-white',
    },
    red: {
      primary: 'text-red-900',
      secondary: 'text-red-700',
      spot: 'text-red-600',
      border: 'border-red-200',
      background: 'bg-red-50',
      inverted: 'text-white',
    },
    warning: {
      primary: 'text-orange-900',
      secondary: 'text-orange-700',
      spot: 'text-orange-600',
      border: 'border-orange-200',
      background: 'bg-orange-50',
      inverted: 'text-white',
    },
    orange: {
      primary: 'text-orange-900',
      secondary: 'text-orange-700',
      spot: 'text-orange-600',
      border: 'border-orange-200',
      background: 'bg-orange-50',
      inverted: 'text-white',
    },
    pink: {
      primary: 'text-pink-900',
      secondary: 'text-pink-700',
      spot: 'text-pink-600',
      border: 'border-pink-200',
      background: 'bg-pink-50',
      inverted: 'text-white',
    },
    purple: {
      primary: 'text-purple-900',
      secondary: 'text-purple-700',
      spot: 'text-purple-600',
      border: 'border-purple-200',
      background: 'bg-purple-50',
      inverted: 'text-white',
    },
    yellow: {
      primary: 'text-yellow-900',
      secondary: 'text-yellow-700',
      spot: 'text-yellow-600',
      border: 'border-yellow-200',
      background: 'bg-yellow-50',
      inverted: 'text-white',
    },
  },
  {
    neutral: {
      primary: 'text-gray-900',
      secondary: 'text-gray-800',
      spot: 'text-gray-700',
      border: 'border-gray-400',
      background: 'bg-gray-200',
      inverted: 'text-white',
    },
    gray: {
      primary: 'text-gray-900',
      secondary: 'text-gray-800',
      spot: 'text-gray-700',
      border: 'border-gray-400',
      background: 'bg-gray-200',
      inverted: 'text-white',
    },
    primary: {
      primary: 'text-blue-900',
      secondary: 'text-blue-800',
      spot: 'text-blue-700',
      border: 'border-blue-300',
      background: 'bg-blue-200',
      inverted: 'text-white',
    },
    blue: {
      primary: 'text-blue-900',
      secondary: 'text-blue-800',
      spot: 'text-blue-700',
      border: 'border-blue-300',
      background: 'bg-blue-200',
      inverted: 'text-white',
    },
    success: {
      primary: 'text-green-900',
      secondary: 'text-green-800',
      spot: 'text-green-700',
      border: 'border-green-300',
      background: 'bg-green-200',
      inverted: 'text-white',
    },
    green: {
      primary: 'text-green-900',
      secondary: 'text-green-800',
      spot: 'text-green-700',
      border: 'border-green-300',
      background: 'bg-green-200',
      inverted: 'text-white',
    },
    error: {
      primary: 'text-red-900',
      secondary: 'text-red-800',
      spot: 'text-red-700',
      border: 'border-red-300',
      background: 'bg-red-200',
      inverted: 'text-white',
    },
    red: {
      primary: 'text-red-900',
      secondary: 'text-red-800',
      spot: 'text-red-700',
      border: 'border-red-300',
      background: 'bg-red-200',
      inverted: 'text-white',
    },
    warning: {
      primary: 'text-orange-900',
      secondary: 'text-orange-800',
      spot: 'text-orange-700',
      border: 'border-orange-300',
      background: 'bg-orange-200',
      inverted: 'text-white',
    },
    orange: {
      primary: 'text-orange-900',
      secondary: 'text-orange-800',
      spot: 'text-orange-700',
      border: 'border-orange-300',
      background: 'bg-orange-200',
      inverted: 'text-white',
    },
    pink: {
      primary: 'text-pink-900',
      secondary: 'text-pink-800',
      spot: 'text-pink-700',
      border: 'border-pink-300',
      background: 'bg-pink-200',
      inverted: 'text-white',
    },
    purple: {
      primary: 'text-purple-900',
      secondary: 'text-purple-800',
      spot: 'text-purple-700',
      border: 'border-purple-300',
      background: 'bg-purple-200',
      inverted: 'text-white',
    },
    yellow: {
      primary: 'text-yellow-900',
      secondary: 'text-yellow-800',
      spot: 'text-yellow-700',
      border: 'border-yellow-300',
      background: 'bg-yellow-200',
      inverted: 'text-white',
    },
  },
] as const

export const shadowClasses = ['shadow-none', 'shadow-md shadow-black/10', 'shadow-lg shadow-black/20'] as const

export const spacingClasses = {
  xxxxs: 'p-0.5',
  xxxs: 'p-1',
  xxs: 'p-1.5',
  xs: 'p-2',
  s: 'p-3',
  m: 'p-4',
  l: 'p-6',
  xl: 'p-8',
  xxl: 'p-10',
} as const

export const gapClasses = {
  xxxxs: 'gap-0',
  xxxs: 'gap-0.5',
  xxs: 'gap-0.75',
  xs: 'gap-1',
  s: 'gap-1.5',
  m: 'gap-2',
  l: 'gap-3',
  xl: 'gap-4',
  xxl: 'gap-5',
} as const

export const fontWeightClasses = {
  regular: 'font-normal',
  semibold: 'font-semibold',
  bold: 'font-bold',
} as const

export const textSizeClasses = {
  xxs: 'text-xxs',
  xs: 'text-xs',
  s: 'text-s',
  m: 'text-m',
  l: 'text-l',
  xl: 'text-xl',
  xxl: 'text-xxl',
  '3xl': 'text-3xl',
  '4xl': 'text-4xl',
  '5xl': 'text-5xl',
} as const

export function isKeyOf<T extends object>(object: T, key: string | number | symbol): key is keyof T {
  return key in object
}

export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max)
}
