export interface SelectBomsInput {
  boms: {
    id: string
    name: string
    description: string
    status: string
    reason: string
    reference: string
  }[]
}

export const selectBomsName = 'selectBoms'

export const selectBomsDescription = 'Select a couple relevant BOMs'

export const selectBomsSchema = {
  type: 'object' as const,
  properties: {
    boms: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          status: {
            type: 'string',
          },
          reference: {
            type: 'string',
          },
          reason: {
            type: 'string',
          },
        },
        required: ['id', 'name', 'description', 'status', 'reason', 'reference'],
      },
    },
  },
  required: ['boms'],
}

export async function selectBomsShared(args: SelectBomsInput) {
  return JSON.stringify(args)
}
