import { createBomCSV, createBomCSVTool } from './createBomCSV'
import { findBom, findBomTool } from './findBom'
import { getBomContent, getBomContentTool } from './getBomContent'
import { selectBoms, selectBomsTool } from './selectBoms'

export const tools = [findBomTool, getBomContentTool, selectBomsTool, createBomCSVTool]

export const toolFunctions = {
  findBom,
  getBomContent,
  selectBoms,
  createBomCSV,
}
