<script lang="ts" setup>
import { Ref } from 'vue'
import { useStyles } from './composables/useStyles'
import { Colors, TextType } from './types'

const props = withDefaults(
  defineProps<{
    name: string
    elevation?: 0 | 1 | 2 | '0' | '1' | '2'
    color?: `${Colors}`
    type?: `${TextType}`
  }>(),
  {
    type: TextType.primary as const,
  },
)

const styles = useStyles(props)

const injectedColor = inject<Ref<`${Colors}` | undefined>>('color', ref(props.color))

const shouldInheritColor = computed(() => {
  return !props.color && !injectedColor.value
})

const iconName = computed(() => {
  if (props.name.includes('carbon-')) {
    return props.name
  }
  return `carbon-${props.name}`
})
</script>

<template>
  <div :i="iconName" :class="[styles.fontColor.value, shouldInheritColor && '!text-inherit']" />
</template>
