import { globalStore } from '~/stores/global'
import { userStore } from '~/stores/user'
import { usersStore } from '~/stores/users'

import { i18n } from '~/modules/i18n'

import router from '~/router'
import { attributesStore } from '~/stores/attributes'
import { configStore } from '~/stores/configs'
import { entityTypesStore } from '~/stores/entity_types'
import { useUserActivity } from '~/stores/useUserActivity'
import { viewsStore } from '~/stores/views'

const t = i18n.global.t

export async function loadStores() {
  userStore().setStoresLoadingState(false)
  globalStore().setStoresLoadingMsg(t('global.loading_user'))
  const userResult = await userStore().loadUser() // load user data from API
  if (userResult?.error) {
    router.push('/auth/login')
    userStore().setStoresLoadingState()
  } else {
    globalStore().setStoresLoadingMsg(t('global.loading_data'))
    await loadAppStores()
  }
}

async function loadAppStores() {
  // loadAppStore will strigger by setOnSnapshotListerner on page load
  const storesPromises = []
  storesPromises.push(usersStore().loadUsers(), configStore().loadClientSettings())

  try {
    Promise.all(storesPromises)
    userStore().setStoresLoadingState()
  } catch {
    window.location.reload()
  }
}

export function clearStores() {
  attributesStore().unsubscribe()
  entityTypesStore().unsubscribe()
  viewsStore().unsubscribe()
  useUserActivity().tearDownListeners()
}
