import { ref, onMounted, onUnmounted, type Ref } from 'vue'

interface InViewportOptions {
  /**
   * Margin around the viewport to consider the element "in view"
   * Can be a single value or CSS-style margin string (e.g., '10px' or '10px 20px')
   */
  margin?: string
  /**
   * Callback function when element enters viewport
   */
  onEnter?: () => void
  /**
   * Callback function when element leaves viewport
   */
  onLeave?: () => void
  /**
   * Performance mode for handling many elements (like table rows)
   * When true, it uses a shared observer instance
   */
  useSharedObserver?: boolean
}

// Shared observer instance for performance optimization
let sharedObserver: IntersectionObserver | null = null
const observedElements = new WeakMap<Element, (entry: IntersectionObserverEntry) => void>()

function getSharedObserver(margin: string = '0px'): IntersectionObserver {
  if (!sharedObserver) {
    sharedObserver = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const callback = observedElements.get(entry.target)
          callback?.(entry)
        })
      },
      {
        root: null,
        rootMargin: margin,
        threshold: 0,
      },
    )
  }
  return sharedObserver
}

export function useInViewport(elementRef: Ref<HTMLElement | null>, options: InViewportOptions = {}) {
  const isInViewport = ref(false)
  let observer: IntersectionObserver | null = null

  onMounted(() => {
    const element = elementRef.value
    if (!element) return

    const handleIntersection = (entry: IntersectionObserverEntry) => {
      const wasInViewport = isInViewport.value
      isInViewport.value = entry.isIntersecting

      // Call appropriate callbacks
      if (isInViewport.value && !wasInViewport) {
        options.onEnter?.()
      } else if (!isInViewport.value && wasInViewport) {
        options.onLeave?.()
      }
    }

    if (options.useSharedObserver) {
      // Use shared observer for better performance with many elements
      observer = getSharedObserver(options.margin)
      observedElements.set(element, handleIntersection)
    } else {
      // Use dedicated observer for individual elements
      observer = new IntersectionObserver(
        entries => {
          handleIntersection(entries[0])
        },
        {
          root: null,
          rootMargin: options.margin || '0px',
          threshold: 0,
        },
      )
    }

    observer.observe(element)
  })

  onUnmounted(() => {
    const element = elementRef.value
    if (element && options.useSharedObserver) {
      // Cleanup for shared observer
      const observer = getSharedObserver()
      observer.unobserve(element)
      observedElements.delete(element)
    } else if (observer) {
      // Cleanup for dedicated observer
      observer.disconnect()
      observer = null
    }
  })

  return {
    isInViewport,
  }
}
