<script setup lang="ts">
import { debounce } from 'lodash'
import { useKeyupHandler } from '~/composables/useKeyupHandler'
import { isForvia } from '~/helpers/growthbook/growthbook'
import { growthBookKey } from '~/modules/growthbook'
import { apiStore } from '~/stores/api'
import { entityTypesStore } from '~/stores/entity_types'
import { unwrapApiErrors } from '~/types/api'
import type { ToastList } from '~/types/toast'
import type { Entity } from '~/types/view-elements'
import { unwrapRouteParam } from '~/utils/route'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const api = apiStore().getApiClient

const state = reactive<{
  currentRevisionState: 'inspect' | 'draft' | 'review'
  isImportModalOpen: boolean
  isCreatingElement: '' | 'node' | 'edge'
  isLoading: boolean
  isLoadingMore: boolean
  currentPage: number
  hasFetchingNext: boolean
  observer: IntersectionObserver | null
  supportedIntersectObserver: boolean
  isReachedAtLast: boolean
  filters: {
    entityTypeId: string
    search: string
    status: string
  }
  toast?: ToastList
  isForvia: boolean
  selectedEntitiesForDelete: string[]
  hasDeleting: boolean
  hasDeleteModalOpen: boolean
  isEntityCreationModalOpen: boolean
  imagePath: string | null
  isFullscreenImageModalOpen: boolean
  canCreateEntity: boolean
}>({
  currentRevisionState: 'draft',
  isImportModalOpen: false,
  isCreatingElement: '',
  isLoading: false,
  isLoadingMore: false,
  currentPage: 1,
  hasFetchingNext: false,
  observer: null,
  supportedIntersectObserver: false,
  isReachedAtLast: false,
  filters: {
    entityTypeId: '',
    search: '',
    status: unwrapRouteParam(route?.query?.status) || '',
  },
  toast: inject<ToastList>('toast'),
  isForvia: false,
  selectedEntitiesForDelete: [],
  hasDeleting: false,
  hasDeleteModalOpen: false,
  isEntityCreationModalOpen: false,
  imagePath: null,
  isFullscreenImageModalOpen: false,
  canCreateEntity: false,
})

const data = reactive({
  entities: [] as Entity[],
})

function openImportModal() {
  state.isImportModalOpen = true
}

function closeImportModal() {
  state.isImportModalOpen = false
}

function openDeleteModal() {
  state.hasDeleteModalOpen = true
}

function closeDeleteModal() {
  state.hasDeleteModalOpen = false
}

function openEntityAdditionModal() {
  state.isEntityCreationModalOpen = true
}

function closeEntityCreationModal() {
  state.isEntityCreationModalOpen = false
}

function filterBySchemaType(value: string) {
  state.selectedEntitiesForDelete = []
  state.filters.entityTypeId = value
  state.currentPage = 1
  state.isReachedAtLast = false
  loadData()
}

function filterByStatus(value: string) {
  state.selectedEntitiesForDelete = []
  state.filters.status = value
  state.currentPage = 1
  loadData()
}

const filterBySearch = debounce(() => {
  state.selectedEntitiesForDelete = []
  state.currentPage = 1
  loadData()
}, 500)

function registerObserver() {
  let options = {
    rootMargin: '0px',
    root: null,
  }

  state.observer = new window.IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        loadPaginatedData(state.currentPage, 15)
      }
    })
  }, options)

  let target = document.querySelector('#issueObserver')
  if (target) {
    state.observer.observe(target)
  }
}

function unRegisterObserver() {
  let target = document.querySelector('#issueObserver')
  if (target && state.observer) {
    state.observer.unobserve(target)
    state.observer = null
  }
}

function goToEntity(row: Entity) {
  if (!row.id && !row.auth.can.view) {
    return
  }

  router.push(`/catalog/${row.uuid}`)
}

function unshiftNewEntity(entity: Entity) {
  data.entities.unshift(entity)
}

function checkOrUncheck(entity: Entity) {
  if (state.selectedEntitiesForDelete.includes(entity.id)) {
    // Remove from array
    const idx = state.selectedEntitiesForDelete.indexOf(entity.id)
    state.selectedEntitiesForDelete.splice(idx, 1)
  } else {
    // Add to remove array
    state.selectedEntitiesForDelete.push(entity.id)
  }
}

async function deleteEntities() {
  state.hasDeleting = true
  try {
    await api.bulkDeleteEntities({
      entity_uuids: state.selectedEntitiesForDelete,
    })

    data.entities = data.entities.filter(entity => !state.selectedEntitiesForDelete.includes(entity.id))
    state.hasDeleteModalOpen = false
    state.selectedEntitiesForDelete = []
    state.currentPage = 1
    loadPaginatedData(1, 15)
    state.toast?.success(t('global.success'), t('entities.bulk_delete_success'))
  } catch (error) {
    const errorMessage = unwrapApiErrors(error)
    state.toast?.error(t('global.error'), errorMessage)
  } finally {
    state.hasDeleting = false
  }
}

async function loadPaginatedData(page: number, perPage: number = 15) {
  if (state.hasFetchingNext || state.isReachedAtLast) return

  if (page === 1) {
    state.isLoading = true
  } else {
    state.isLoadingMore = true
  }
  state.hasFetchingNext = true

  try {
    const [response] = await Promise.all([
      api.getEntities(page, perPage, {
        entity_type_id: state.filters.entityTypeId,
        search: state.filters.search,
        status: state.filters.status,
      }),
      entityTypesStore().loadEntityTypes(),
    ])

    if (response?.data) {
      data.entities.push(...response.data)

      if (response.meta.current_page >= response.meta.last_page) state.isReachedAtLast = true

      if (response?.auth?.can?.create) {
        state.canCreateEntity = response?.auth?.can?.create
      }

      if (data.entities.length <= 0) {
        state.selectedEntitiesForDelete = []
      }

      state.currentPage += 1
      state.hasFetchingNext = false
      state.isLoadingMore = false
    }
  } catch (error) {
    // router.push('/')
    state.canCreateEntity = false
    const errorMessage = unwrapApiErrors(error)
    state.toast?.error(t('global.error'), errorMessage)
  } finally {
    if (page === 1) {
      state.isLoading = false
    } else {
      state.isLoadingMore = false
    }
  }
}

function openFullScreenImageModal(imagePath: string) {
  if (!imagePath) {
    return
  }

  state.imagePath = imagePath
  state.isFullscreenImageModalOpen = true
}

function closeFullScreenImageModal() {
  state.isFullscreenImageModalOpen = false
}

async function loadData() {
  data.entities = []
  state.isReachedAtLast = false
  loadPaginatedData(1, 15)
}

function selectOrUnselectAllEntities(selectAll: boolean) {
  if (selectAll) {
    state.selectedEntitiesForDelete = data.entities.map(entity => entity.id)
  } else {
    state.selectedEntitiesForDelete = []
  }
}

/** Permission functions start */
const canCreateEntity = computed(() => {
  return state.canCreateEntity
})
/** Permission functions end */

onBeforeMount(async () => {
  const growthBookInjectable = inject(growthBookKey)
  const growthBook = await growthBookInjectable?.init()
  state.isForvia = (await isForvia(growthBook)) || false
})

const handleEscapeKey = () => {
  closeDeleteModal()
  closeEntityCreationModal()
}

useKeyupHandler(handleEscapeKey)

onMounted(() => {
  if (!window.IntersectionObserver) {
    state.supportedIntersectObserver = false
  } else {
    state.supportedIntersectObserver = true
  }

  loadData()
})

onBeforeUnmount(() => {
  unRegisterObserver()
})

watch(
  () => data.entities.length,
  () => {
    registerObserver()
  },
)

watch(
  () => state.isReachedAtLast,
  newVal => {
    if (newVal) {
      unRegisterObserver()
    }
  },
)

const tabs = [
  { key: 'type', label: t('global.type'), maxWidth: 72 },
  { key: 'name', label: t('global.name') },
  { key: 'image', label: t('global.image'), maxWidth: 72 },
  { key: 'status', label: t('global.status'), noWrap: true, maxWidth: 100 },
  { key: 'description', label: t('global.description') },
  { key: 'updated_at', label: t('global.last_updated'), type: 'date', noWrap: true, maxWidth: 140 },
]

function rowSelect(entity: Entity | boolean) {
  if (typeof entity === 'boolean') {
    selectOrUnselectAllEntities(entity)
    return
  }
  checkOrUncheck(entity)
}
</script>

<template>
  <div class="flex flex-1 flex-col gap-2 px-6 pt-6">
    <div class="flex gap-2 mb-4">
      <div class="flex gap-2 mr-auto">
        <LibraryEntitiesFilters
          :entity-type-id="state.filters.entityTypeId"
          @filterSchemaType="filterBySchemaType"
          :status="state.filters.status"
          @filter-status="filterByStatus"
        />
        <OSearchBar
          v-model="state.filters.search"
          :placeholder="$t('global.search')"
          class="!w-96"
          @update:model-value="filterBySearch"
          data-test-id="search"
        />
      </div>

      <button
        class="btn-danger gap-1.5 h-full"
        v-if="state.selectedEntitiesForDelete.length > 0"
        @click="openDeleteModal"
        data-test-id="delete-entities"
      >
        <CIcon name="trash-can" />
        {{ $t('global.delete') }}
      </button>

      <button class="btn-secondary gap-1.5 h-full h-fit" @click="openImportModal" v-if="canCreateEntity">
        <CIcon name="upload" />
        {{ $t('global.import') }}
      </button>

      <button class="btn-primary h-full" @click="openEntityAdditionModal()" v-if="canCreateEntity">
        <CIcon name="intent-request-create" />
        {{ $t('global.new') }}
      </button>
    </div>

    <CTable
      :columns="tabs"
      :rows="data.entities"
      :is-loading="state.isLoading"
      :is-loading-more="state.isLoadingMore"
      :empty-message="$t('entities.empty_list')"
      @row-select="rowSelect"
      @row-click="goToEntity($event)"
      test-id="entity"
    >
      <template #type="{ item: entity }">
        <EntityIcon :entity="entity" :tooltip="true" />
      </template>

      <template #image="{ item: entity }">
        <div
          class="col-span-1 max-h-10 overflow-hidden rounded"
          @click.prevent.stop="openFullScreenImageModal(entity?.image)"
        >
          <img :src="entity.image" v-if="entity?.image" class="object-contain w-[42px] h-auto aspect-square" />
        </div>
      </template>

      <template #status="{ item: entity }">
        <div class="flex items-center gap-2">
          <PublishingStatusTag :status="entity.status" />
        </div>
      </template>
    </CTable>

    <div class="pt-6" id="issueObserver" />

    <GraphElementImportModal
      :open="state.isImportModalOpen"
      import-from="catalog"
      @close="closeImportModal"
      @import-success="loadData"
    />
    <EntityCreationModal
      :open="state.isEntityCreationModalOpen"
      @close="closeEntityCreationModal"
      @create="unshiftNewEntity"
    />

    <!-- Delete Entity -->
    <OModal :open="state.hasDeleteModalOpen" data-test-id="delete-modal">
      <template #content>
        <div class="flex flex-col gap-1">
          <h2 class="text-xl font-semibold">
            {{ $t('global.confirm_delete') }}
          </h2>
          <p>
            {{ $t('entities.bulk_delete_title') }}
          </p>
          <span
            class="mt-4 rounded-md bg-red-50 px-2 py-1 text-s font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
          >
            <b>Warning:&nbsp;</b>{{ $t('entities.bulk_delete_description') }}
          </span>
        </div>
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-secondary" :disabled="state.hasDeleting" @click="closeDeleteModal">
            {{ t('global.cancel') }}
          </button>
          <button class="btn-danger" autofocus :disabled="state.hasDeleting" @click="deleteEntities">
            {{ state.hasDeleting ? t('global.deleting') : t('global.delete') }}
          </button>
        </div>
      </template>
    </OModal>

    <!-- Image modal -->
    <OModal :open="state.isFullscreenImageModalOpen" @close="closeFullScreenImageModal">
      <template #content>
        <img :src="state.imagePath" />
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-primary w-full" @click="closeFullScreenImageModal">
            {{ $t('global.close') }}
          </button>
        </div>
      </template>
    </OModal>
  </div>
</template>
