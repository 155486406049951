import tippy, { Instance } from 'tippy.js'
import { ref, watch, type Ref } from 'vue'

export function useTooltip({ selector, content }: { selector: string; content?: string | Ref<string> }) {
  const instances = ref<Instance[] | null>(null)

  function setUp() {
    if (instances.value) {
      return
    }

    const options: Parameters<typeof tippy>[1] = {}

    if (content) {
      options.content = typeof content === 'object' ? content.value : content
    }

    instances.value = tippy(selector, options)
  }

  if (content && typeof content === 'object') {
    watch(content, newContent => {
      if (instances.value && newContent) {
        instances.value.forEach(instance => {
          instance.setContent(newContent)
        })
      }
    })
  }

  function destroy() {
    if (instances.value) {
      instances.value.forEach(instance => {
        instance.unmount()
        instance.destroy()
      })
    }

    instances.value = null
  }

  return {
    setUp,
    destroy,
  }
}
