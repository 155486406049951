import { computed, reactive, toRefs } from 'vue'
import { Colors, FontWeight, Spacing, TextSize, TextType } from '../types'
import {
  clamp,
  colorClasses,
  fontWeightClasses,
  gapClasses,
  isKeyOf,
  shadowClasses,
  spacingClasses,
  textSizeClasses,
} from '../utils'

interface StyleProps {
  size?: `${TextSize}`
  weight?: `${FontWeight}`
  elevation?: 0 | 1 | 2 | '0' | '1' | '2'
  color?: `${Colors}`
  textType?: `${TextType}`
  type?: `${TextType}`
  spacing?: `${Spacing}`
  border?: boolean
  shadow?: boolean
}

const defaults = {
  size: TextSize.m as const,
  weight: FontWeight.regular as const,
  textType: TextType.primary as const,
  spacing: Spacing.s as const,
  border: true,
  shadow: false,
}

export function useDefaults(props: StyleProps) {
  const reactiveProps = reactive(props)
  const propsRefs = toRefs(reactiveProps)

  return {
    size: computed(() => propsRefs.size?.value ?? defaults.size),
    weight: computed(() => propsRefs.weight?.value ?? defaults.weight),
    color: propsRefs.color,
    textType: computed(() => propsRefs.type?.value ?? propsRefs.textType?.value ?? defaults.textType),
    spacing: computed(() => propsRefs.spacing?.value ?? defaults.spacing),
    border: computed(() => propsRefs.border?.value ?? defaults.border),
    shadow: computed(() => propsRefs.shadow?.value ?? defaults.shadow),
    elevation: propsRefs.elevation,
  }
}

export function useStyles(props: StyleProps) {
  const { size, weight, elevation, color, textType, spacing, border, shadow } = useDefaults(props)

  const injectedColor = inject<Ref<`${Colors}` | undefined>>('color', ref(color))

  const colorValue = computed<`${Colors}`>(() => {
    if (color?.value && Object.values(Colors).includes(color.value as Colors)) {
      return color.value
    }

    const injectedColorValue = injectedColor.value

    if (!injectedColorValue) {
      return Colors.gray
    }

    if (Object.values(Colors).includes(injectedColorValue as Colors)) {
      return injectedColorValue
    }

    return Colors.gray
  })

  const minElevation = computed(() => (colorValue.value === Colors.gray || colorValue.value === Colors.neutral ? 0 : 1))

  const injectedElevation = inject<Ref<0 | 1 | 2>>('elevation', minElevation)

  const defaultElevation = computed(() => {
    return clamp(injectedElevation.value, minElevation.value, 2)
  })

  const elevationValue = computed(() => {
    const elevationNumber = Number(elevation?.value)
    return Number.isNaN(elevationNumber) ? defaultElevation.value : elevationNumber
  })

  const sizeValue = computed(() => {
    if (Object.values(TextSize).includes(size.value as TextSize)) {
      return size.value
    }
    return TextSize.m
  })

  const weightValue = computed(() => {
    if (Object.values(FontWeight).includes(weight.value as FontWeight)) {
      return weight.value
    }
    return FontWeight.regular
  })

  const textTypeValue = computed(() => {
    if (Object.values(TextType).includes(textType.value as TextType)) {
      return textType.value
    }
    return TextType.primary
  })

  const spacingValue = computed(() => {
    if (Object.values(Spacing).includes(spacing.value as Spacing)) {
      return spacingClasses[spacing.value]
    }
    return spacingClasses[Spacing.s]
  })

  const gapValue = computed(() => {
    if (Object.values(Spacing).includes(spacing.value as Spacing)) {
      return gapClasses[spacing.value]
    }

    return gapClasses[Spacing.s]
  })

  const fontColor = computed(() => {
    const elevationColors = colorClasses[elevationValue.value]
    if (isKeyOf(elevationColors, colorValue.value)) {
      return elevationColors[colorValue.value][textTypeValue.value]
    }

    throw new Error(`Color ${colorValue.value} is not valid with elevation ${elevationValue.value}`)
  })

  const backgroundColor = computed(() => {
    const elevationColors = colorClasses[elevationValue.value]
    if (isKeyOf(elevationColors, colorValue.value)) {
      return elevationColors[colorValue.value]['background']
    }

    throw new Error(`Color ${colorValue.value} is not valid with elevation ${elevationValue.value}`)
  })

  const borderColor = computed(() => {
    const elevationColors = colorClasses[elevationValue.value]
    if (isKeyOf(elevationColors, colorValue.value)) {
      return elevationColors[colorValue.value]['border']
    }

    throw new Error(`Color ${colorValue.value} is not valid with elevation ${elevationValue.value}`)
  })

  const shadowColor = computed(() => {
    return shadowClasses[elevationValue.value]
  })

  return {
    elevation: elevationValue,
    color: colorValue,
    fontSize: computed(() => textSizeClasses[sizeValue.value]),
    fontWeight: computed(() => fontWeightClasses[weightValue.value]),
    fontColor,
    spacing: spacingValue,
    gap: gapValue,
    backgroundColor,
    border: computed(() => (border.value ? `border ${borderColor.value}` : '')),
    shadow: computed(() => (shadow.value ? shadowColor.value : '')),
  }
}
