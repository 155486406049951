import { signInWithEmailAndPassword, Unsubscribe, User } from 'firebase/auth'
import { loadStores } from '~/controllers/stores'
import ApiHelper from '~/helpers/ApiHelper'
import { fb_auth, on_auth_state_changed } from '~/services/firebase'
import { fbStore } from '~/stores/fb'
import { userStore } from '~/stores/user'
import { useUserActivity } from '~/stores/useUserActivity'
let unsubscribe: Unsubscribe | null = null

export async function loadUser(): Promise<void> {
  fbStore().logEvent('loadUser')
  unsubscribe = on_auth_state_changed(fb_auth, async (user: User | null) => {
    if (user) {
      useUserActivity().setUpListeners()
      await loadStores()
    } else {
      userStore().setStoresLoadingState()
    }
  })
}

export function getUserId() {
  fbStore().logEvent('getUserId')
  const user = fb_auth.currentUser
  if (user) {
    const userId: string = user?.uid ? user?.uid : ''
    return userId
  }
  return ''
}

export async function signIn(email: string, password: string) {
  fbStore().logEvent('signIn')

  await signInWithEmailAndPassword(fb_auth, email, password)
  await loadUser()
}
export async function logout() {
  const user = fb_auth.currentUser

  if (user) {
    const api = new ApiHelper()
    await api.logout()
    unsubscribe?.()
  }

  fbStore().logEvent('logout')
  await fb_auth.signOut()
}
